import { useEffect, useState } from 'react';
import useSessionStore from "../Lib/Context";
import { GPTEventTracking } from '../Lib/gpt-ad-manager';
import Spinner from './Spinner';
import WatchVideo from '../Pages/WatchVideo';


export function GAMSetup(){
    const { SessionData, setSessionData } = useSessionStore();
    const[IsReady,setIsReady] = useState(false);
    const[ISTargetingReady,setISTargetingReady] = useState(false);
    const GAMAPIReady = true;


    function Targeting(){
        const PageAdUnit_1 = ["page1_header","page1_display","video_pri","online_header","online_display"]
        const PageAdUnit_2 = ["page2_header","page2_display","video_sec","online2_header","online2_display"]

        let AdUnitTargeting = {}
        let UserRedirectUrl = {}
        let UserRedirectUrl_2 = {}

        if(SessionData.ageCap){
            SessionData.campaign_code = SessionData.targeting.lsale
            SessionData.campaign_code_fb = SessionData.targeting.lsale_fb

            PageAdUnit_1.forEach( Element => {
                AdUnitTargeting[Element] = {
                    "lsale" : SessionData.targeting.lsale,
                    "partner" : SessionData.partner,
                    "apMac" : SessionData.ap_mac_norm
                }
            });
            PageAdUnit_2.forEach( Element => {
                AdUnitTargeting[Element] = {
                    "lsale" : SessionData.targeting.lsale_fb,
                    "partner" : SessionData.partner,
                    "apMac" : SessionData.ap_mac_norm
                }
            });

            if(SessionData.targeting.url){
                UserRedirectUrl = {
                    "UserRedirectUrl": SessionData.targeting.url
               }
            }else {
                UserRedirectUrl = {
                    "UserRedirectUrl": SessionData.default_click_through_url
               }
            }

            if(SessionData.targeting.url_fb){
                UserRedirectUrl_2 = {
                    "UserRedirectUrl_2": SessionData.targeting.url_fb
               }
            }else{
                UserRedirectUrl_2 = {
                    "UserRedirectUrl_2": SessionData.default_click_through_url
               }
            }
        }else{
            SessionData.campaign_code = SessionData.targeting.lsale_fb
            SessionData.campaign_code_fb = SessionData.targeting.lsale_fb
            PageAdUnit_1.forEach( Element => {
                AdUnitTargeting[Element] = {
                    "lsale" : SessionData.targeting.lsale_fb,
                    "partner" : SessionData.partner,
                    "apMac" : SessionData.ap_mac_norm
                }
            });
            PageAdUnit_2.forEach( Element => {
                AdUnitTargeting[Element] = {
                    "lsale" : SessionData.targeting.lsale_fb,
                    "partner" : SessionData.partner,
                    "apMac" : SessionData.ap_mac_norm
                }
            });
            if(SessionData.targeting.url_fb){
                UserRedirectUrl = {
                    "UserRedirectUrl": SessionData.targeting.url_fb,
                    "UserRedirectUrl_2": SessionData.targeting.url_fb
               }
            }else {
                UserRedirectUrl = {
                    "UserRedirectUrl": SessionData.default_click_through_url,
                    "UserRedirectUrl_2": SessionData.default_click_through_url
               }
            }
        }
            setSessionData({...SessionData, ...UserRedirectUrl, ...UserRedirectUrl_2 ,AdUnitTargeting})
            setISTargetingReady(true)
    }
    
    useEffect(() => {
        if(GAMAPIReady){
            GPTEventTracking(SessionData)
            Targeting()
        }
    },[GAMAPIReady])

    useEffect(() => {
        if(ISTargetingReady){
            console.log(SessionData)
            setIsReady(true)
        }
    },[ISTargetingReady])

    if(!IsReady){
        return(
            <Spinner />
        )
    
        
    }else{
        return(
            <WatchVideo />
        )
        
    }
}