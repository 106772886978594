import { useEffect, useState } from 'react';
import useSessionStore from "../Lib/Context";
import { GAMSetup } from '../Components/GAMSetup';
import Spinner from '../Components/Spinner';
import { PreChecks } from '../Lib/Prechecks';
import { AgeCap } from '../Components/AgeCap';
import { InitializeAds } from '../Lib/gpt-ad-manager';
import ReactGA from "react-ga4";


function MiddleWare() {
    const { SessionData, setSessionData } = useSessionStore();
    const DoingPreCheck = PreChecks()
    const [ ErrorState, setErrorState] = useState(false)
    const [ isLoading, setisLoading] = useState(true)
    const [GA4Loaded, setGA4Loaded] = useState(false)
    const [LoadAgeCap, setLoadAgeCap] = useState(false)
    const GAMAPIReady = InitializeAds(SessionData);

    function Initialize_GA4(){
        if(SessionData.google_analytics_tag){
            ReactGA.initialize(
            [
                {
                  trackingId: "G-H1XNVC8QDN",
                },
                {
                  trackingId: SessionData.google_analytics_tag,
                },
              ])
              setGA4Loaded(true)
        }else{
            ReactGA.initialize(
            [
                {
                  trackingId: "G-H1XNVC8QDN",
                },
              ])
              setGA4Loaded(true)
        }
    }

    useEffect(()=>{
        if(DoingPreCheck){
            if(GAMAPIReady){
                if(!GA4Loaded){
                    Initialize_GA4()
                }else{
                        if(SessionData.targeting.age_cap_ad){
                            setLoadAgeCap(true)
                        }else{
                            //setLoadAgeCap(true)
                            setisLoading(false)
                        }
                    }
                    
                }
        }
    }
    ,[DoingPreCheck,GA4Loaded,GAMAPIReady])

   
if(LoadAgeCap){
        return <AgeCap />
}
if (isLoading) {
    return <Spinner />;
}

if(ErrorState){
    return (
        <div>
          {JSON.stringify(SessionData)}
        </div>
      );
}
else
{
    return (
        <div>
            {/* <LeadForm /> */}
            <GAMSetup />
        </div>
    )
}

  
}

export default MiddleWare;