import { useEffect, useState } from 'react';
import { adSizes } from './adSlotsSizes';
import { logs } from './fetch';
import useSessionStore from "../Lib/Context";

const googletag = window.googletag || (window.googletag = { cmd: [] });


let adSlots = {};

function filterObjectKeys(obj, allowedKeys) {
    return Object.keys(obj).reduce((filteredObj, key) => {
      if (allowedKeys.includes(key)) {
        filteredObj[key] = obj[key];
      }
      return filteredObj;
    }, {});
  }

function cleanAdUnitPath(adUnitPath) {
    var result = adUnitPath.split("/")
    return result[3]
};

export function GPTEventTracking(data){
  googletag.cmd.push(() => {
    googletag.pubads().addEventListener("slotOnload", (event) => {
        var elementPath = event.slot.getAdUnitPath();
        var slotId = cleanAdUnitPath(elementPath);

        var onloadEvents = {
            'sessionID': data.sessionID,
            'event': "slotOnload",
            'slotID': slotId,
            'serviceName': "thinkzone",
            'slot': slotId,
        }
        logs(onloadEvents,data)
        
    });
    googletag.pubads().addEventListener('impressionViewable', function(event) {
        var elementPath = event.slot.getAdUnitPath();
        var slotId = cleanAdUnitPath(elementPath);
        var Impressiondetails = {
            'sessionID': data.sessionID,
            'event': "impressionViewable",
            'slotID': slotId,
            'serviceName': "thinkzone",
            'slot': slotId,
    }
    logs(Impressiondetails,data)
    });

    googletag.pubads().addEventListener('slotRenderEnded', function(event) {
        var elementPath = event.slot.getAdUnitPath();
        var slotId = cleanAdUnitPath(elementPath);
    
        var details = {
            'sessionID': data.sessionID,
            'event': "slotRenderEnded",
            'slotID': slotId,
            'slot': slotId,
            'serviceName': "thinkzone",
            'advertiserId': event.advertiserId,
            'campaignId': event.campaignId,
            'companyId': event.companyIds,
            'isBackfill?': event.isBackfill,
            'isEmpty?': event.isEmpty,
            'labelIDs': event.labelIds,
            'lineItemId': event.lineItemId,
            'size': event.size,
            'slotContentChanged?': event.slotContentChanged,
            'sourceAgnosticCreativeId': event.sourceAgnosticCreativeId,
            'sourceAgnosticLineItemId': event.sourceAgnosticLineItemId,
            'yieldGroupId': event.yieldGroupIds
        }
    
        logs(details,data);
    });

    googletag.pubads().addEventListener('slotRequested', function(event) {
        var elementPath = event.slot.getAdUnitPath();
        var slotId = cleanAdUnitPath(elementPath);

        var requestEvents = {
        'sessionID': data.sessionID,
        'event': "slotRequested",
        'slotID': slotId,
        'serviceName': "thinkzone",
        'slot': slotId,
        }

        logs(requestEvents,data)
    });

    googletag.pubads().addEventListener('slotResponseReceived', function(event) {
        var elementPath = event.slot.getAdUnitPath();
        var slotId = cleanAdUnitPath(elementPath);

        var receivedEvents = {
        'sessionID': data.sessionID,
        'event': "slotResponseReceived",
        'slotID': slotId,
        'serviceName': "thinkzone",
        'slot': slotId,
        }
        logs(receivedEvents,data)
     });
});
};

export const InitializeAds = () => {
    const [isApiReady, setIsApiReady] = useState(false);
    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://securepubads.g.doubleclick.net/tag/js/gpt.js';
        script.async = true;
        script.crossOrigin = "anonymous"
    
        document.head.appendChild(script);

        script.onload = () => {
            //GPTEventTracking(data)
            googletag.cmd.push(() => {
                // Disable initial load, to precisely control when ads are requested.
                googletag.pubads().disableInitialLoad();
            
                // Enable SRA and services.
                //googletag.pubads().enableSingleRequest();
                googletag.enableServices();

                setIsApiReady(true)
              });
        };
        return () => {
            // Clean up if needed (e.g., remove script element)
          };

    },[]);
    return isApiReady;
    
}

export const EnableRewardedVideo = (AdUnit) => {
    const [isRewardedReady, setisRewarded] = useState(false)
    const [isRewardGranted, setisRewardGranted] = useState(false)
    const [videoErrorState, setVideoErrorState] = useState(false);
    const { SessionData, setSessionData } = useSessionStore();

    useEffect(() => {
        // Ensure GPT is available
        if (window.googletag && googletag.apiReady) {
          // Define the ad slot for an out-of-page rewarded video
          googletag.cmd.push(() => {
            // Create a new out-of-page ad slot for the rewarded video
            const rewardedSlot = googletag.defineOutOfPageSlot(`/21967555500/thinkzone/${AdUnit.adUnit}`, googletag.enums.OutOfPageFormat.REWARDED,);
            
            // Make sure the slot was created successfully
            if (rewardedSlot) {
              if(AdUnit.adUnit === "video_pri"){
                Object.keys(SessionData.AdUnitTargeting.video_pri).map(key => (
                  rewardedSlot.setTargeting(key, SessionData.AdUnitTargeting.video_pri[key] )
                ));
              }else if(AdUnit.adUnit === "video_sec"){
                Object.keys(SessionData.AdUnitTargeting.video_sec).map(key => (
                  rewardedSlot.setTargeting(key, SessionData.AdUnitTargeting.video_sec[key] )
                ));
              }   
                rewardedSlot.addService(googletag.pubads());
              googletag.pubads().addEventListener("rewardedSlotReady", (event) => {
                var elementPath = event.slot.getAdUnitPath();
                let slotId = cleanAdUnitPath(elementPath);
                let Impressiondetails =
                  {
                    'sessionID': SessionData.sessionID,
                    'event': "rewardedSlotReady",
                    'slotID': slotId,
                    'serviceName': "thinkzone",
                    'slot': slotId,
                    'payload': 1
                    };
                logs(Impressiondetails,SessionData)
                // event.makeRewardedVisible();
                setisRewarded(event)
            });
              // Listen for events like when the ad is closed or viewed
              googletag.pubads().addEventListener('rewardedSlotClosed', function (event) {
                var elementPath = event.slot.getAdUnitPath();
                let slotId = cleanAdUnitPath(elementPath);
                let Impressiondetails =
                  {
                    'sessionID': SessionData.sessionID,
                    'event': "rewardedSlotClosed",
                    'slotID': slotId,
                    'serviceName': "thinkzone",
                    'slot': slotId,
                    'payload': 1
                    };
              logs(Impressiondetails,SessionData)

                  setVideoErrorState(true)

              });
    
              googletag.pubads().addEventListener('rewardedSlotGranted', function (event) {
                var elementPath = event.slot.getAdUnitPath();
                let slotId = cleanAdUnitPath(elementPath);
                let Impressiondetails =
                  {
                    'sessionID': SessionData.sessionID,
                    'event': "rewardedSlotGranted",
                    'slotID': slotId,
                    'serviceName': "thinkzone",
                    'slot': slotId,
                    'payload': 1
                    };
              logs(Impressiondetails,SessionData)

                setisRewardGranted(true)
                setTimeout(() => {
                    dismissRewardedAd();
                  }, 2000);
              });
              googletag.display(rewardedSlot);
              googletag.pubads().refresh([rewardedSlot])
            }else {
                console.log("Rewarded ads are not supported on this page.");
              }
              function dismissRewardedAd(){
                googletag.cmd.push(() => {
                      googletag.destroySlots([rewardedSlot]);
                    })
                  };
          });
        }
      }, []);

      return [isRewardedReady, isRewardGranted, videoErrorState];
      
    };

export function EnableAdUnits(AdSlot){
    
    
    useEffect(() => {
        const slotId = AdSlot.Name;
        const adUnit = `${AdSlot.Network}${AdSlot.Name}`;
        const Targeting = AdSlot.Targeting
        googletag.cmd.push(() => {
            const slot = googletag.defineSlot(adUnit, adSizes[AdSlot.Name], slotId);
            if (slot){
                Object.keys(Targeting).map(key => (
                    slot.setTargeting(key, Targeting[key])
                ));
                slot.addService(googletag.pubads())
                googletag.display(slot)
                adSlots[slotId] = slot;
                
            }
    });
        return () => {
            googletag.cmd.push(() => {
              if (adSlots[slotId]) {
                googletag.destroySlots([adSlots[slotId]]);
                delete adSlots[slotId];
              }
            });
        }; 
    }, []);
    
    return (
        <div>
          <div id={AdSlot.Name}></div>
        </div>
      );
    
}
export function ClearAds(clearList){
    googletag.cmd.push(() => {
        clearList.forEach((value) => {
            if (adSlots[value]) {
                googletag.destroySlots([adSlots[value]]);
                delete adSlots[value];
              }
        })
        
      });
}

export const LoadAds = (AdsToReload) =>{
    
    useEffect(() => {
        const allowedKeys = AdsToReload.Ads
        const refresAds = filterObjectKeys(adSlots, allowedKeys)
        googletag.cmd.push(() => {
          const slots = Object.values(refresAds);
          googletag.pubads().refresh(slots);
        });
      }, []);
}
