import { EnableAdUnits, LoadAds } from '../Lib/gpt-ad-manager';
import { useEffect, useState } from 'react';
import useSessionStore from "../Lib/Context";
import { GAMSetup } from './GAMSetup';
import "../App.css";
import Terms from "../Components/Terms&Service";
import Text from '../Components/Text';
import { Box2, CusButton, AppHeader, BtnContainer2, InfoTextParagraph, LinkButton, CustomTextContainer } from '../Components/ResponsiveLayout';



export function AgeCap(){
    const { SessionData, setSessionData } = useSessionStore();
    const [QuestionText,setQuestionText] = useState();
    const [AgeCapCompleted, setAgeCapCompleted] = useState(false);
    const [IsTOSRequested, setIsTOSRequested] = useState(false)

    function requestTOS(){
        setIsTOSRequested(true)
    }

    useEffect(()=>{
        if(SessionData.targeting.age_cap_text){
            setQuestionText(SessionData.targeting.age_cap_text)
        }else{
            setQuestionText("Are you older then 18?")
        }  
    },[])

    
    const HandleSelect = (e) => {
        if(e){
            SessionData.ageCap = true
        }else{
            SessionData.ageCap = false
        }
        setSessionData(SessionData)
        setAgeCapCompleted(true)
    }
    if(AgeCapCompleted){
        return (
            <div>
                <GAMSetup />
            </div>
        )
    }
    if (IsTOSRequested){
        return <Terms />
    }

    return (
        <div>
            <div id="Header">
                <AppHeader>
                    <EnableAdUnits Name="powerby" Network="/21967555500/thinkzone/" Targeting={SessionData.partner}/>
                    <LoadAds Ads={["powerby"]}/>
                </AppHeader>
                </div>
            
            <CustomTextContainer style={{ flex: 'none', paddingTop: '53px', paddingBottom: '53px' }}>
                <Text.Text>
                    Connect to <span className="bold-text">Fast Free Wifi</span>
                </Text.Text>
            </CustomTextContainer>
        

            <Box2 className='example-style'>
                {/* <CustomTextContainer style={{ flex: 'none' }}> */}
                    <Text.Text  style={{ paddingBottom: '5px' }}>
                        {/* {QuestionText} */}
                        Please select the option that 
                        best suits you:
                    </Text.Text>
                    {/* <div className='d-flex'> */}
                        <BtnContainer2 style={{ paddingBottom: '20px', paddingTop: '20px' }}>
                            <CusButton onClick={() => HandleSelect(true)}> I am over 18 and a user of nicotine 
                                or tobacco products
                            </CusButton>
                        </BtnContainer2>

                        <BtnContainer2 style={{ paddingBottom: '40px' }}>
                            <CusButton onClick={() => HandleSelect(false)}> I am not over 18 and/or prefer to
                                 not receive information on smoke-free alternatives
                            </CusButton>
                        </BtnContainer2> 
                    {/* </div> */}
                {/* </CustomTextContainer> */}
            </Box2> 
            <footer className="footer">
                <InfoTextParagraph>
                    By connecting, you agree to the Think Wifi 
                    <br />
                    {' '} 
                    <LinkButton style={{ paddingBottom: '20px' }} className = "sticky-button"  onClick={requestTOS}>
                        Terms & Conditions
                    </LinkButton>
                </InfoTextParagraph>
            </footer>

        </div>
    );
}
